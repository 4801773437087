import SendIcon from '@mui/icons-material/Send'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import AutoCompleteCategory from 'components/atoms/AutoCompleteCategory'
import UserInviteAutocomplete from 'components/atoms/UserInviteAutocomplete'
import { useEffect, useState } from 'react'
import { ControllerRenderProps, useForm } from 'react-hook-form'
import {
  AutocompleteGroupItem,
  AutocompleteItem,
  InviteUserFormFields,
} from 'types/FormFields'
import { getRegex } from 'utils/helpers'

const UserInvite = ({
  loadingRoles,
  loadingBillingAcc,
  loadingSites,
  loadingReports,
  inviteLoading,
  resetInvite = false,
  roleList = [],
  billingAccList = [],
  siteList = [],
  onBillingAccChange,
  onInviteUser,
  reportList = [],
  onEndAdornmentClick,
}: {
  loadingRoles: boolean
  loadingBillingAcc: boolean
  loadingSites: boolean
  loadingReports: boolean
  inviteLoading: boolean
  resetInvite: boolean
  roleList: AutocompleteItem[]
  billingAccList: AutocompleteItem[]
  siteList: AutocompleteGroupItem[]
  reportList: AutocompleteItem[]
  onBillingAccChange: (billingAccList: AutocompleteItem[]) => void
  onInviteUser: (data: InviteUserFormFields, error: any) => void
  onEndAdornmentClick?: (field: ControllerRenderProps<any, string>) => void
}): JSX.Element => {
  const [errorMsg] = useState('')
  const {
    register,
    handleSubmit,
    setValue,
    resetField,
    control,
    watch,
    formState: { errors },
  } = useForm<InviteUserFormFields>({ mode: 'onChange' })
  const [disabledReports, setDisabledReports] = useState<string[]>([])

  const watchBillingAccount = watch('billingAccounts')
  const watchRoles = watch('accessRoles')

  useEffect(() => {
    if (resetInvite) {
      resetField('userEmail')
      resetField('userFullName')
    }
  }, [resetInvite])

  useEffect(() => {
    if (watchRoles && watchRoles.length) {
      const selectedRoleIds = watchRoles.map(role => role.id)
      const associatedReports = reportList.filter(report =>
        report.roles?.some(roleId => selectedRoleIds.includes(roleId))
      )
      setDisabledReports(associatedReports.map(report => report.id))
    } else {
      setDisabledReports([])
    }
  }, [watchRoles, reportList])

  useEffect(() => {
    onBillingAccChange(watchBillingAccount)
  }, [watchBillingAccount])

  return (
    <Card
      component="form"
      onSubmit={handleSubmit(onInviteUser)}
      sx={{
        width: '100%',
        background: '#fff',
      }}
      noValidate
      autoComplete="off"
    >
      <CardHeader title="Invite Users" />
      <CardContent>
        <Grid container rowSpacing={2} columnSpacing={2} className="pa-0">
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              label="Email Address"
              autoFocus
              {...register('userEmail', {
                required: 'Email is Required.',
                pattern: {
                  value: getRegex('email'),
                  message: 'Invalid Email Address.',
                },
              })}
              error={errors?.userEmail ? true : false}
              onBlur={(
                e: React.FocusEvent<
                  HTMLInputElement | HTMLTextAreaElement,
                  Element
                >
              ): void => setValue('userEmail', e.target.value.trim())}
              helperText={
                errors?.userEmail && errors.userEmail?.message
                  ? String(errors.userEmail.message)
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              size="small"
              label="Full Name"
              color="primary"
              {...register('userFullName', {
                required: 'Name is Required.',
                pattern: {
                  value: getRegex('name'),
                  message:
                    'Name must be between 2-256 characters in length and cannot contain < or >.',
                },
              })}
              onBlur={(
                e: React.FocusEvent<
                  HTMLInputElement | HTMLTextAreaElement,
                  Element
                >
              ): void => setValue('userFullName', e.target.value.trim())}
              error={errors?.userFullName ? true : false}
              sx={{ fontSize: '0.6rem' }}
              helperText={
                errors?.userFullName && errors.userFullName?.message
                  ? String(errors.userFullName.message)
                  : ''
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserInviteAutocomplete
              control={control}
              options={billingAccList}
              label="Billing Account"
              fieldName="billingAccounts"
              loading={loadingBillingAcc}
            />

            <Box
              sx={{
                marginTop: 2,
              }}
            >
              {watchBillingAccount?.map((account, index: number) => (
                <Grid
                  container
                  spacing={1}
                  key={account.id}
                  alignItems="center"
                  sx={{ marginBottom: 1 }}
                >
                  <Grid item xs={6}>
                    <Typography variant="body1">{account.label}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      size="small"
                      placeholder={`${account.label}`}
                      inputProps={{
                        maxLength: 10,
                        inputMode: 'numeric',
                        pattern: '[0-9]*',
                      }}
                      label="Pello ID"
                      autoFocus
                      {...register(`billingAccounts.${index}.pelloId`, {
                        //required: 'This field is required',
                        maxLength: {
                          value: 10,
                          message: 'Maximum length is 10 characters',
                        },
                        pattern: {
                          value: /^[0-9]*$/, // Allow only numeric characters
                          message: 'Only numeric characters are allowed',
                        },
                      })}
                      error={!!errors?.billingAccounts?.[index]?.pelloId}
                      helperText={
                        errors?.billingAccounts?.[index]?.pelloId?.message
                      }
                    />
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <AutoCompleteCategory
              control={control}
              options={siteList}
              multiple={true}
              label="Sites"
              fieldName="sites"
              loading={loadingSites}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserInviteAutocomplete
              control={control}
              options={roleList}
              label="Role(s)"
              filterSelectedOptions
              fieldName="accessRoles"
              loading={loadingRoles}
              onEndAdornmentClick={onEndAdornmentClick}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <UserInviteAutocomplete
              control={control}
              options={reportList.map(report => ({
                ...report,
                disabled: disabledReports.includes(report.id),
              }))}
              label="Select Report(s)"
              filterSelectedOptions
              fieldName="reportIds"
              loading={loadingReports}
            />
          </Grid>
          <Grid item xs={12} sx={{ p: 0 }}>
            <Divider>
              {errorMsg ? (
                <FormHelperText
                  error={errorMsg !== ''}
                  sx={{ textAlign: 'center' }}
                >
                  {errorMsg}
                </FormHelperText>
              ) : null}
            </Divider>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="start"
            alignItems="center"
          >
            <LoadingButton
              size="small"
              endIcon={<SendIcon />}
              loading={inviteLoading}
              loadingPosition="end"
              variant="outlined"
              type="submit"
            >
              Send Invite
            </LoadingButton>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default UserInvite
