import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import AdminPageWrapper from 'components/molecules/AdminPageWrapper'
import { useAtom } from 'jotai'
import { useEffect, useRef, useState } from 'react'
import { alertAtom } from 'stores'
import {
  InviteUser,
  ReportsCollection,
  UpdateUser,
  UsersType,
} from 'types/user'

import {
  accessTokenAtom,
  getUserRolesList,
  inviteUser,
  getCosmosReportsList,
} from 'stores/auth'
import { useMsal } from '@azure/msal-react'
import {
  AutocompleteGroupItem,
  AutocompleteItem,
  InviteUserFormFields,
} from 'types/FormFields'

import { getUsers, updateUser } from 'stores/user'
import UserInvite from 'components/organisms/UserInvite'
import UserList from 'components/organisms/UserList'
import { groupBy, map, uniqBy } from 'lodash'
import {
  getBillingAccountList,
  getSitesByBillingAccountIds,
} from 'stores/billing'
import UserDetails from 'components/organisms/UserDetails'
import { LoadingButton } from '@mui/lab'
import { Send } from '@mui/icons-material'
import RolesMatrixGrid from 'components/organisms/RolesMatrixGrid'
import { ControllerRenderProps } from 'react-hook-form'
export interface FormHandles {
  submitForm: () => void
}

const Users = (): JSX.Element => {
  const theme = useTheme()
  const [accessToken] = useAtom(accessTokenAtom)
  const { instance } = useMsal()
  const activeAccount = instance.getActiveAccount()
  const [, setAlert] = useAtom(alertAtom)
  const [userList, setUserList] = useState<UsersType[]>([])
  const [roleAcList, setRoleAcList] = useState<AutocompleteItem[]>([])
  const [billingAccList, setBillingAccList] = useState<AutocompleteItem[]>([])
  const [siteList, setSiteList] = useState<AutocompleteGroupItem[]>([])
  const [loadingRoles, setLoadingRoles] = useState<boolean>(false)
  const [loadingUsers, setLoadingUsers] = useState<boolean>(false)
  const [loadingBillingAcc, setLoadingBillingAcc] = useState<boolean>(false)
  const [loadingSites, setLoadingSites] = useState<boolean>(false)
  const [loadingReports, setLoadingReports] = useState<boolean>(false)
  const [inviteLoading, setInviteLoading] = useState<boolean>(false)
  const [resetInvite, setResetInvite] = useState<boolean>(false)
  const [reportList, setreportList] = useState<
    (AutocompleteItem & { roles: string[] | [] })[]
  >([])
  const [userToViewOrEdit, onViewOrEditUser] = useState<UsersType | null>(null)
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
  const [userSaving, setUserSaving] = useState<boolean>(false)
  const userDetailsRef = useRef<FormHandles>(null)
  const [userDetailsFormValid, setUserDetailsFormValid] = useState(false)
  const [showRolesMatrix, setShowRolesMatrix] = useState<boolean>(false)
  const [reInviteLoading, setReInviteLoading] = useState(false)

  useEffect(() => {
    if (accessToken) {
      getReportsList().then(reportsCollection => {
        if (reportsCollection) {
          getRolesList(reportsCollection)
        }
      })
      getBillingAccountsList()
    }
  }, [accessToken])

  useEffect(() => {
    if (accessToken && billingAccList.length) {
      getUsersList()
    }
  }, [accessToken, billingAccList])

  const getUsersList = (): void => {
    setLoadingUsers(true)
    getUsers()
      .then(response => {
        const userList = response.data.map((user: UsersType) => {
          return {
            ...user,
            ...{
              billingAccountNames:
                user?.billingAccounts?.map(
                  billingAccount => billingAccount.name
                ) || [],
            },
            ...{
              roles: map(user.accessRoles, 'roleName'),
            },
          }
        })
        setUserList(userList)
        setLoadingUsers(false)
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to load users list',
          autoHideDuration: 2000,
        })
        setLoadingUsers(false)
      })
  }

  const getRolesList = (reportsCollection: ReportsCollection[] = []): void => {
    setLoadingRoles(true)
    getUserRolesList()
      .then(response => {
        setRoleAcList(
          response.data.map(role => {
            const description = []
            if (role.description) {
              description.push(role.description)
            }

            const reports = reportsCollection
              .filter(report => report.reportAccessByRole?.includes(role.id))
              .map(report => report.reportName)
              .join(', ')

            if (reports) {
              description.push(`Reports: ${reports}`)
            }

            return {
              label: role.roleName,
              id: role.id,
              description: description,
            }
          })
        )
        setLoadingRoles(false)
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to load roles list',
          autoHideDuration: 2000,
        })
        setLoadingRoles(false)
      })
  }

  const getReportsList = (): Promise<void | ReportsCollection[]> => {
    setLoadingReports(true)
    return getCosmosReportsList()
      .then(response => {
        setreportList(
          response.data.map(report => ({
            label: report.reportName,
            id: report.id,
            roles: report.reportAccessByRole || [],
          }))
        )

        setLoadingReports(false)
        return response.data
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to load reports list',
          autoHideDuration: 2000,
        })
        setLoadingReports(false)
      })
  }

  const getBillingAccountsList = async (): Promise<void> => {
    setLoadingBillingAcc(true)
    await getBillingAccountList()
      .then(response => {
        // setAccounts(response.data)
        setBillingAccList(
          response.data
            .map(account => ({
              label: account.billingAccountName,
              id: account.billingAccountId,
              accountNumber: account.accountNumber,
            }))
            .sort((a, b) => a.label.localeCompare(b.label))
        )
        setLoadingBillingAcc(false)
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to load accounts list',
          autoHideDuration: 2000,
        })
        setLoadingBillingAcc(false)
      })
  }

  //get Sites by billingAccount
  const getSitesOnBillingAccChange = async (
    billingAccList: AutocompleteItem[] = []
  ): Promise<void> => {
    //Reset sites
    setLoadingSites(true)
    setSiteList([])

    if (billingAccList && billingAccList.length) {
      const billingAccountIds = billingAccList.map(billingAcc => billingAcc.id)

      await getSitesByBillingAccountIds(billingAccountIds)
        .then(response => {
          const data = response.data
          if (data.length) {
            const updateSites: AutocompleteGroupItem[] = data
              .map(site => ({
                label: site.name,
                id: site.id,
                accountNumber: site.accountNumber,
                groupBy:
                  billingAccList.find(
                    billingAcc => billingAcc.id === site.customerId
                  )?.label || 'Unknown Account',
                groupById: site.customerId,
              }))
              .sort((a, b) => a.label.localeCompare(b.label))

            setSiteList(uniqBy(updateSites, 'id'))
          }
          setLoadingSites(false)
        })
        .catch(() => {
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to load sites list',
            autoHideDuration: 2000,
          })
          setLoadingSites(false)
        })
    } else {
      setLoadingSites(false)
    }
  }

  const handleReInviteUser = (user: UsersType): void => {
    if (activeAccount) {
      setReInviteLoading(true)
      const userToInvite: InviteUser = {
        userEmail: user.emailAddress,
        userFullName: user.fullName,
        billingAccounts: user.billingAccounts,
        accessRoles: user.accessRoles.map(role => role.roleId),
        invitationSendBy: activeAccount?.localAccountId,
        reportIds: user.reportIds,
      }

      inviteUser(userToInvite)
        .then(() => {
          setAlert({
            show: true,
            type: 'success',
            message: `Invitation Resent to ${userToInvite.userEmail}.`,
            autoHideDuration: 4000,
          })
          setReInviteLoading(false)
        })
        .catch(() => {
          setReInviteLoading(false)
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to resend invite. Try again.',
            autoHideDuration: 2000,
          })
        })
    }
  }

  const handleInviteUser = ({
    userEmail,
    userFullName,
    billingAccounts,
    sites,
    accessRoles,
    reportIds,
  }: InviteUserFormFields): void => {
    if (
      userEmail &&
      userFullName &&
      billingAccounts.length &&
      accessRoles &&
      activeAccount
    ) {
      setInviteLoading(true)
      const groupedSelectedSites = groupBy(sites, 'groupById')
      const allGroupedSites = groupBy(siteList, 'groupById')
      const updatedBillingAccounts = billingAccounts.map(billingAccount => {
        return {
          id: billingAccount.id,
          name: billingAccount.label,
          totalSitesCount: allGroupedSites[billingAccount.id].length,
          accountNumber: billingAccount.accountNumber,
          pelloId: billingAccount.pelloId,
          sites:
            groupedSelectedSites[billingAccount.id]?.map(site => {
              return {
                id: site.id,
                name: site.label,
                accountNumber: site.accountNumber,
              }
            }) || [],
        }
      })

      const userToInvite: InviteUser = {
        userEmail: userEmail,
        userFullName: userFullName,
        billingAccounts: updatedBillingAccounts,
        accessRoles: accessRoles.map(role => role.id),
        invitationSendBy: activeAccount?.localAccountId,
        reportIds: reportIds?.length ? reportIds.map(report => report.id) : [],
      }

      inviteUser(userToInvite)
        .then(() => {
          setInviteLoading(false)
          // Update the userslist on invite success
          getUsersList()
          setAlert({
            show: true,
            type: 'success',
            message: `Invitation sent to ${userToInvite.userEmail}.`,
            autoHideDuration: 4000,
          })
          setResetInvite(true)
        })
        .catch(() => {
          setInviteLoading(false)
          setAlert({
            show: true,
            type: 'error',
            message: 'Failed to invite user. Try again.',
            autoHideDuration: 2000,
          })
        })
    }
  }

  const handleSubmit = (): void => {
    if (userDetailsRef.current) {
      userDetailsRef.current.submitForm()
    }
  }

  const handleSaveUser = ({
    userEmail,
    userFullName,
    billingAccounts,
    sites,
    accessRoles,
    reportIds,
  }: InviteUserFormFields): void => {
    if (
      userEmail &&
      userFullName &&
      billingAccounts.length &&
      accessRoles &&
      activeAccount
    ) {
      if (userToViewOrEdit?.adObjectId) {
        setUserSaving(false) // update to true
        const groupedSelectedSites = groupBy(sites, 'groupById')
        const allGroupedSites = groupBy(siteList, 'groupById')
        const updatedBillingAccounts = billingAccounts.map(billingAccount => {
          return {
            id: billingAccount.id,
            name: billingAccount.label,
            totalSitesCount: allGroupedSites[billingAccount.id]?.length,
            accountNumber: billingAccount.accountNumber,
            pelloId: billingAccount.pelloId,
            sites:
              groupedSelectedSites[billingAccount.id]?.map(site => {
                return {
                  id: site.id,
                  name: site.label,
                  accountNumber: site.accountNumber,
                }
              }) || [],
          }
        })

        const userToSave: UpdateUser = {
          adObjectid: userToViewOrEdit.adObjectId,
          userEmail: userEmail,
          fullName: userFullName,
          billingAccounts: updatedBillingAccounts,
          userRole: accessRoles.map(role => role.id),
          reportIds: reportIds?.length
            ? reportIds.map(report => report.id)
            : [],
          hasAcceptedCustomerPrivacyAndTerms:
            userToViewOrEdit.hasAcceptedCustomerPrivacyAndTerms,
          metaData: {},
        }

        updateUser(userToSave)
          .then(() => {
            setUserSaving(false)
            // Update the userslist on user update success
            onViewOrEditUser(null)
            getUsersList()
            setAlert({
              show: true,
              type: 'success',
              message: `${userEmail} Updated.`,
              autoHideDuration: 4000,
            })
            setResetInvite(true)
          })
          .catch(() => {
            setUserSaving(false)
            setAlert({
              show: true,
              type: 'error',
              message: 'Failed to update user. Try again.',
              autoHideDuration: 2000,
            })
          })
      }
    }
  }

  const handleClose = (): void => {
    onViewOrEditUser(null)
  }

  const handleRolesMatrixClose = (): void => {
    setShowRolesMatrix(false)
  }

  const handleEndAdornmentClick = (
    field: ControllerRenderProps<any, string>
  ): void => {
    if (field.name === 'accessRoles') {
      setShowRolesMatrix(true)
    }
  }

  return (
    <AdminPageWrapper
      pageTitle="Users"
      loading={
        loadingRoles ||
        loadingUsers ||
        loadingBillingAcc ||
        loadingSites ||
        loadingReports
      }
    >
      <Container
        maxWidth={false}
        component="div"
        disableGutters
        sx={{
          justifyContent: { xs: 'center' },
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Dialog
          maxWidth="md"
          fullScreen={fullScreen}
          fullWidth
          open={showRolesMatrix}
          onClose={handleRolesMatrixClose}
        >
          <DialogTitle id="user-details-dialog-title">
            Roles & Permissions
          </DialogTitle>
          <DialogContent>
            <RolesMatrixGrid />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleRolesMatrixClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          maxWidth="md"
          fullScreen={fullScreen}
          open={userToViewOrEdit !== null}
          onClose={handleClose}
          aria-labelledby="user-details-dialog-title"
        >
          <DialogTitle id="user-details-dialog-title">
            View or Edit User
          </DialogTitle>
          <DialogContent>
            {userToViewOrEdit ? (
              <Box>
                <UserDetails
                  ref={userDetailsRef}
                  user={userToViewOrEdit}
                  loadingRoles={loadingRoles}
                  loadingBillingAcc={loadingBillingAcc}
                  loadingSites={loadingSites}
                  loadingReports={loadingReports}
                  roleList={roleAcList}
                  billingAccList={billingAccList}
                  siteList={siteList}
                  onBillingAccChange={getSitesOnBillingAccChange}
                  reportList={reportList}
                  onSaveUser={handleSaveUser}
                  onValidityChange={setUserDetailsFormValid}
                />
              </Box>
            ) : (
              'No billing accounts available for the selected user.'
            )}
          </DialogContent>
          <DialogActions>
            <LoadingButton
              size="small"
              endIcon={<Send />}
              loading={userSaving}
              loadingPosition="end"
              onClick={handleSubmit}
              disabled={!userDetailsFormValid}
            >
              Update
            </LoadingButton>
            <Button onClick={handleClose} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>

        <UserInvite
          loadingRoles={loadingRoles}
          loadingBillingAcc={loadingBillingAcc}
          loadingSites={loadingSites}
          loadingReports={loadingReports}
          inviteLoading={inviteLoading}
          roleList={roleAcList}
          billingAccList={billingAccList}
          siteList={siteList}
          resetInvite={resetInvite}
          onInviteUser={handleInviteUser}
          onBillingAccChange={getSitesOnBillingAccChange}
          reportList={reportList}
          onEndAdornmentClick={handleEndAdornmentClick}
        />
        <Backdrop
          sx={(theme): any => ({
            color: '#fff',
            zIndex: theme.zIndex.drawer + 1,
          })}
          open={reInviteLoading}
          onClick={handleClose}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <CircularProgress color="inherit" />
            <Typography variant="body2">Sending Invite...</Typography>
          </Box>
        </Backdrop>
        <UserList
          userList={userList}
          loading={loadingUsers}
          onViewOrEditUser={onViewOrEditUser}
          updateUsers={getUsersList}
          onReInviteUser={handleReInviteUser}
        />
      </Container>
    </AdminPageWrapper>
  )
}

export default Users
