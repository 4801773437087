import { CheckCircleOutline, HighlightOff } from '@mui/icons-material'
import { Box } from '@mui/material'
import { ColDef, ICellRendererParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import Loading from 'components/molecules/Loading'
import { useAtom } from 'jotai'
import { useEffect, useMemo, useState } from 'react'
import { alertAtom } from 'stores'
import { getCosmosReportsList, getUserRolesList } from 'stores/auth'
import { ReportsCollection, UserRole } from 'types/user'

interface RolesMatrixGridProps {
  onLoadingChange?: (loading: boolean) => void
  onLoadingReportsChange?: (loadingReports: boolean) => void
}

const RolesMatrixGrid = ({
  onLoadingChange,
  onLoadingReportsChange,
}: RolesMatrixGridProps): JSX.Element => {
  const [rolesPermissionsRowData, setRolesPermissionsRowData] = useState<any[]>(
    []
  )
  const [rolesPermissionsColumnDefs, setRolesPermissionsColumnDefs] = useState<
    any[]
  >([])
  const [reportsRowData, setReportsRowData] = useState<any[]>([])
  const [reportsColumnDefs, setReportsColumnDefs] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingReports, setLoadingReports] = useState<boolean>(false)
  const [rolesData, setRolesData] = useState<UserRole[]>([])
  const [reportData, setReportData] = useState<ReportsCollection[]>([])

  const [, setAlert] = useAtom(alertAtom)

  useEffect(() => {
    getRolesList()
  }, [])

  const getRolesList = (): void => {
    setLoading(true)
    setLoadingReports(true)
    onLoadingChange?.(true)
    onLoadingReportsChange?.(true)

    getUserRolesList()
      .then(async response => {
        setRolesData(response.data)
        setLoading(false)
        onLoadingChange?.(false)
        gerReportsList()
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to load roles list',
          autoHideDuration: 2000,
        })
        setLoading(false)
        onLoadingChange?.(false)
      })
  }

  const gerReportsList = (): void => {
    getCosmosReportsList()
      .then(reportResp => {
        setReportData(reportResp.data)
        setLoadingReports(false)
        onLoadingReportsChange?.(false)
      })
      .catch(() => {
        setAlert({
          show: true,
          type: 'error',
          message: 'Failed to load reports',
          autoHideDuration: 2000,
        })
        setLoadingReports(false)
        onLoadingReportsChange?.(false)
      })
  }

  useEffect(() => {
    if (rolesData && rolesData.length) {
      const columns = mapRolesToHeaders(rolesData)
      const { rows } = generateTableData(rolesData)
      setRolesPermissionsColumnDefs(columns)
      setRolesPermissionsRowData(transformData(rows))
    }
  }, [rolesData])

  useEffect(() => {
    if (reportData && reportData.length) {
      const columns = mapReportsToHeaders(rolesData)
      const rows = generateReportsTableData(reportData, rolesData)
      setReportsColumnDefs(columns)
      setReportsRowData(rows)
    }
  }, [reportData, rolesData])

  const mapRolesToHeaders = (roles: any): any => {
    const columns = roles.map((role: any) => {
      return {
        headerName: role.roleName.includes(':')
          ? role.roleName.split(':')[0]
          : role.roleName,
        children: [
          {
            headerName: 'View',
            field: role.roleName + '.View',
            cellStyle: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            cellRenderer: (params: ICellRendererParams): JSX.Element => {
              if (params.value && params.value === 'Yes') {
                return <CheckCircleOutline color="primary" />
              }

              return <HighlightOff color="error" />
            },
            width: 92,
          },
          {
            headerName: 'Create',
            field: role.roleName + '.Create',
            cellStyle: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
            cellRenderer: (params: ICellRendererParams): JSX.Element => {
              if (params.value && params.value === 'Yes') {
                return <CheckCircleOutline color="primary" />
              }

              return <HighlightOff color="error" />
            },
            width: 92,
          },
        ],
      }
    })

    columns.unshift({
      sortable: false,
      suppressMovable: true,
      resizable: true,
      pinned: 'left',
      headerName: 'Permissions',
      field: 'permissions',
    })
    return columns
  }

  const mapReportsToHeaders = (roles: any): any => {
    const columns = roles.map((role: any) => {
      return {
        headerName: role.roleName.includes(':')
          ? role.roleName.split(':')[0]
          : role.roleName,
        field: role.roleName,
        cellStyle: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        cellRenderer: (params: ICellRendererParams): JSX.Element => {
          if (params.value && params.value === 'Yes') {
            return <CheckCircleOutline color="primary" />
          }

          return <HighlightOff color="error" />
        },
        width: 92,
      }
    })

    columns.unshift({
      sortable: false,
      suppressMovable: true,
      resizable: true,
      pinned: 'left',
      headerName: 'Reports',
      field: 'reports',
      width: 350,
    })
    return columns
  }

  const generateTableData = (roles: any[]): any => {
    const rows: any = {}
    const permissions = new Set()

    roles.forEach(role => {
      const roleName = role.roleName

      role.permissions.forEach((permissionObj: any) => {
        const permission = permissionObj.permission
        if (permission) {
          const [scope] = permission.split(':').slice(1)
          const action = permission.split(':').pop()
          const key = `${scope}`
          permissions.add(key)

          if (!rows[key]) {
            rows[key] = {}
          }

          if (!rows[key][roleName]) {
            rows[key][roleName] = { View: 'No', Create: 'No' }
          }

          if (
            action === 'OrgRead' ||
            action === 'AllRead' ||
            action === 'Read'
          ) {
            rows[key][roleName].View = 'Yes'
          } else if (
            action === 'OrgWrite' ||
            action === 'AllCreate' ||
            action === 'Create'
          ) {
            rows[key][roleName].Create = 'Yes'
          }
        }
      })
    })

    const rowArray = Array.from(permissions).map((permission: any) => {
      return {
        [permission]: rows[permission],
      }
    })

    return {
      rows: rowArray,
    }
  }

  const generateReportsTableData = (reports: any[], roles: any[]): any[] => {
    return reports.map(report => {
      const row: any = { reports: report.reportName }
      roles.forEach(role => {
        row[role.roleName] = report.reportAccessByRole?.includes(role.id)
          ? 'Yes'
          : 'No'
      })
      return row
    })
  }

  const transformData = (data: any[]): any => {
    return data.map((item: any) => {
      const [permissionKey, permissionValues] = Object.entries(item)[0]

      const transformedItem: any = { permissions: permissionKey }
      if (permissionValues && typeof permissionValues === 'object') {
        for (const [key, value] of Object.entries(permissionValues)) {
          transformedItem[key] = value
        }
      }
      return transformedItem
    })
  }

  const getRowStyle = (params: any): any => {
    if (params.data.adObjectId === null) {
      return { background: '#ff586e1c', cursor: 'not-allowed' }
    }
  }

  const defaultColDef: ColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
      suppressMovable: true,
      flex: 1,
      minWidth: 100,
    }
  }, [])

  return (
    <Box>
      <Box className="ag-theme-alpine" sx={{ height: 520, width: '100%' }}>
        {!loading ? (
          <AgGridReact
            rowData={rolesPermissionsRowData}
            columnDefs={rolesPermissionsColumnDefs}
            getRowStyle={getRowStyle}
            defaultColDef={defaultColDef}
            columnHoverHighlight={true}
          />
        ) : (
          <Loading height="520px" message="Loading Roles..." />
        )}
      </Box>

      <Box
        className="ag-theme-alpine"
        sx={{ height: 520, width: '100%', mt: 3 }}
      >
        {!loadingReports ? (
          <AgGridReact
            rowData={reportsRowData}
            columnDefs={reportsColumnDefs}
            getRowStyle={getRowStyle}
            defaultColDef={{ ...defaultColDef, minWidth: 150 }}
            columnHoverHighlight={true}
          />
        ) : (
          <Loading height="520px" message="Loading Reports..." />
        )}
      </Box>
    </Box>
  )
}
export default RolesMatrixGrid
