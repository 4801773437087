import axios from 'axios'
import User from 'services/user'
// import { logout } from 'stores/auth'
import RoleService from 'services/roles'
import AccountService from 'services/account'
import ReportService from 'services/report'
import { msalInstance } from 'index'
import { loginRequest } from 'authConfig'

// Axios init start -----------------
const axiosFuncAppInstance = axios.create({
  baseURL: process.env.REACT_APP_FUNC_API_BASE_URL,
})

const axiosDataverseAppInstance = axios.create({
  baseURL: process.env.REACT_APP_DATAVERSE_API_BASE_URL,
})

const axiosPortalAppInstance = axios.create({
  baseURL: process.env.REACT_APP_FUNC_PORTAL_API_BASE_URL,
})

axiosFuncAppInstance.interceptors.request.use(
  async request => {
    const activeAccount = msalInstance.getActiveAccount()
    if (!activeAccount) {
      console.error('No active account found. User may be logged out.')
      return request
    }

    try {
      const response = await msalInstance.acquireTokenSilent(loginRequest)
      request.headers.Authorization = `Bearer ${response.accessToken}`
    } catch (error) {
      console.error('Failed to acquire token silently:', error)
    }

    const subKey = process.env.REACT_APP_SUBSCRIPTION_KEY
    if (subKey) {
      if (request.headers) {
        request.headers['Ocp-Apim-Subscription-Key'] = subKey
      }
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

axiosDataverseAppInstance.interceptors.request.use(
  async request => {
    const activeAccount = msalInstance.getActiveAccount()
    if (!activeAccount) {
      console.error('No active account found. User may be logged out.')
      return request
    }

    try {
      const response = await msalInstance.acquireTokenSilent(loginRequest)
      request.headers.Authorization = `Bearer ${response.accessToken}`
    } catch (error) {
      console.error('Failed to acquire token silently:', error)
    }

    const subKey = process.env.REACT_APP_SUBSCRIPTION_KEY
    if (subKey) {
      if (request.headers) {
        request.headers['Ocp-Apim-Subscription-Key'] = subKey
      }
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

axiosPortalAppInstance.interceptors.request.use(
  async request => {
    const activeAccount = msalInstance.getActiveAccount()
    if (!activeAccount) {
      console.error('No active account found. User may be logged out.')
      return request
    }

    try {
      const response = await msalInstance.acquireTokenSilent(loginRequest)
      request.headers.Authorization = `Bearer ${response.accessToken}`
    } catch (error) {
      console.error('Failed to acquire token silently:', error)
    }

    const subKey = process.env.REACT_APP_SUBSCRIPTION_KEY
    if (subKey) {
      if (request.headers) {
        request.headers['Ocp-Apim-Subscription-Key'] = subKey
      }
    }
    return request
  },
  error => {
    return Promise.reject(error)
  }
)

axiosFuncAppInstance.interceptors.response.use(
  response => response,
  error => {
    // GA-Track exception
    // exceptionEvent(error)
    if (error.response.status === 401) {
      //logout()
      return Promise.reject(error)
    }

    if (error.response.status === 403) {
      // logout()
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

axiosDataverseAppInstance.interceptors.response.use(
  response => response,
  error => {
    // GA-Track exception
    // exceptionEvent(error)
    if (error.response.status === 401) {
      //logout()
      return Promise.reject(error)
    }

    if (error.response.status === 403) {
      // logout()
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

axiosPortalAppInstance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      //logout()
      return Promise.reject(error)
    }

    if (error.response.status === 403) {
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export {
  axiosFuncAppInstance,
  axiosDataverseAppInstance,
  axiosPortalAppInstance,
}

// Axios init end -----------------
export default {
  User: new User(),
  Role: new RoleService(),
  Account: new AccountService(),
  Report: new ReportService(),
}
