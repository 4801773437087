import { Card, CardContent, Container } from '@mui/material'
import AdminPageWrapper from 'components/molecules/AdminPageWrapper'
import RolesMatrixGrid from 'components/organisms/RolesMatrixGrid'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { accessTokenAtom } from 'stores/auth'

const Roles = (): JSX.Element => {
  const [accessToken] = useAtom(accessTokenAtom)
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingReports, setLoadingReports] = useState<boolean>(false)

  return (
    <AdminPageWrapper
      pageTitle="Roles & Permissions"
      loading={loading || loadingReports}
    >
      <Container
        maxWidth={false}
        component="div"
        disableGutters
        sx={{
          justifyContent: { xs: 'center' },
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Card
          sx={{
            width: '100%',
            mt: 2,
            background: '#fff',
          }}
        >
          <CardContent>
            {accessToken ? (
              <RolesMatrixGrid
                onLoadingChange={setLoading}
                onLoadingReportsChange={setLoadingReports}
              />
            ) : null}
          </CardContent>
        </Card>
      </Container>
    </AdminPageWrapper>
  )
}
export default Roles
